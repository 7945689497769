import { useRef, useState, useEffect, useContext } from "react";
import styles from "./Chat.module.css";
import { chatApi, RetrievalMode, ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, ResponseMessage, FilterRequest, getConversation } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { Box, Tooltip, Typography } from "@mui/joy";
import { Filters } from "../../components/Filters";
import { useAuth, useClerk } from "@clerk/clerk-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ResultsTable } from "../../components/ResultsTable";
import loading_animation_1 from "../../assets/loading_animation_1.gif";
import loading_animation_2 from "../../assets/loading_animation_2.gif";
import loading_animation_3 from "../../assets/loading_animation_3.gif";
import { animations, recordEvent, removeDuplicates } from "../../util/utils";
import { UserContext } from "../../contexts/UserContext";
import React from "react";

// Preload your GIFs when the component mounts or when appropriate

const Chat = () => {
    const location = useLocation();
    const [gifsLoaded, setGifsLoaded] = useState(false);
    const [searchParams] = useSearchParams();
    const [conversationId, setConversationId] = useState<string | undefined>(undefined);
    const { isSignedIn, getToken } = useAuth();
    const { redirectToSignIn } = useClerk();
    const { updateUser } = useContext(UserContext);
    const [filters, setFilters] = useState<FilterRequest>({});
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // eslint-disable-next-line
    const [error, setError] = useState<any>();
    const navigate = useNavigate();
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const lastAnswerRef = useRef<HTMLDivElement | null>(null);
    let currentChatType = searchParams.get("chatType") || "chat";
    const [currentSkip, setSkip] = useState<number>(0);

    useEffect(() => {
        const preloadGifs = async () => {
            const urls = [loading_animation_1, loading_animation_2, loading_animation_3];
            const loadedGifs = await Promise.all(
                urls.map(async url => {
                    const response = await fetch(url);
                    return URL.createObjectURL(await response.blob());
                })
            );
            animations.push(...loadedGifs);
            setGifsLoaded(true);
        };
        preloadGifs();
    }, []);

    useEffect(() => {
        if (conversationId === searchParams.get("conversationId")) {
            return;
        } else if (searchParams.get("conversationId")) {
            loadConversation(searchParams.get("conversationId"));
            setConversationId(searchParams.get("conversationId") || undefined);
        } else {
            setAnswers([]);
            setConversationId(undefined);
            lastQuestionRef.current = "";
        }
    }, [searchParams]);

    const loadConversation = async (conversationId: string | null) => {
        if (!conversationId) {
            return;
        }
        const resp = await getConversation(conversationId, await getToken({ template: "Standard" }));
        const parsedResp = await resp.json();
        setAnswers(parsedResp["messages"]);
        currentChatType = parsedResp["chat_type"];
        searchParams.set("chatType", parsedResp["chat_type"]);
        lastQuestionRef.current = parsedResp["messages"][parsedResp["messages"].length - 1][0];
    };

    const makeApiRequest = async (question: string, skip?: number) => {
        lastQuestionRef.current = question;
        recordEvent("sent_message", { chat_type: currentChatType });
        setSkip(skip || 0);
        if (error) setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        // Clear previous results if it's a new search from the top bar
        if (currentChatType === "find") {
            setAnswers([]);
            setConversationId(undefined);
        }

        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                conversation_id: conversationId || undefined,
                messages: [...messages, { content: question, role: "user" }],
                stream: false,
                context: {
                    overrides: {
                        exclude_category: undefined,
                        top: 4,
                        retrieval_mode: RetrievalMode.Hybrid,
                        semantic_ranker: true,
                        semantic_captions: false,
                        suggest_followup_questions: true,
                        filters: filters,
                        skip: currentSkip
                    },
                    chat_type: currentChatType || "chat"
                },
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };
            const response = await chatApi(
                request,
                await getToken({
                    template: "Standard"
                })
            );
            if (!response.body) {
                throw Error("No response body");
            }

            const parsedResponse: ChatAppResponseOrError = await response.json();
            if (response.status > 299 || !response.ok) {
                throw Error(parsedResponse.error || "Unknown error");
            }
            setAnswers(prevAnswers => [...prevAnswers, [question, parsedResponse as ChatAppResponse]]);
            setConversationId(String(parsedResponse.conversation_id));
            searchParams.set("conversationId", parsedResponse.conversation_id);
            navigate(location.pathname + "?" + searchParams.toString());
            recordEvent("answer_received", { conversationId: parsedResponse.conversation_id });
            updateUser();
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isLoading) {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        } else {
            lastAnswerRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [answers, isLoading]);

    const onExampleClicked = (example: string) => {
        if (!isSignedIn) {
            redirectToSignIn();
        } else {
            makeApiRequest(example);
        }
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }
        setSelectedAnswer(index);
    };

    return (
        <div className={styles.container}>
            <Filters
                filters={filters}
                includeFilters={["jurisdiction", "doc_type", "court"]}
                setFilters={setFilters}
                includeSearch={currentChatType === "find" && answers.length > 0}
                setSearch={question => makeApiRequest(question)}
                searchPlaceholder="Ask a question"
            />
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <div className={styles.chatEmptyStateTitle}>
                                <Typography color={"primary"} variant={"plain"} level={"h1"}>
                                    Ask me anything.
                                </Typography>
                            </div>
                            <div className={styles.questionBox}>
                                <QuestionInput
                                    clearOnSend
                                    placeholder="Type your question here."
                                    onSend={question => makeApiRequest(question)}
                                    showChatSelect={true}
                                />
                            </div>
                            <ExampleList onExampleClicked={onExampleClicked} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt} ref={index === answers.length - 1 ? lastAnswerRef : null}>
                                        <Answer
                                            isStreaming={false}
                                            key={index}
                                            answer={answer[1]}
                                            chatType={currentChatType}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={answers.length - 1 === index}
                                            triggerMoreResults={
                                                answers.length - 1 === index ? () => makeApiRequest(lastQuestionRef.current, currentSkip + 7) : undefined
                                            }
                                        />
                                    </div>
                                    {!!lastQuestionRef.current && currentChatType == "find" && !isLoading && (
                                        <div className={styles.chatMessageGpt}>
                                            <ResultsTable documents={answers.length > 0 ? removeDuplicates(answers[0][1].documents, "name") : []} />
                                        </div>
                                    )}
                                </div>
                            ))}

                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading gifsLoaded={gifsLoaded} />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
                </div>
                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}
            </div>

            <Box className={styles.footer}>
                {!!lastQuestionRef.current && currentChatType == "chat" && (
                    <Tooltip title={"New topic? For better results - try a new chat"} placement="top">
                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder="Type a follow up question"
                                onSend={question => makeApiRequest(question)}
                                showChatSelect={false}
                            />
                        </div>
                    </Tooltip>
                )}
            </Box>
        </div>
    );
};

export default Chat;
