// Desc: Constants used throughout the application

export interface Filter {
    searchable?: boolean; // Whether the filter should be searchable
    displayName: string; // Name to show user
    options: option[]; // Options
    fieldName: string; // Field name to send to backend (name of field in scraper/ database)
    reliesOn?: { key: string; value: string | null }; // If this filter relies on another filter being selected  ( key = fieldName of other filter, value = value to match)
    type?: string; // Type of option
    filterBy?: string; // Field to filter by
}

interface option {
    // Name to show user
    displayValue: string;
    // Value to send to backend
    value: string;
    // Group to which the option belongs
    group?: string;
}

export const FILTERS_AU: Filter[] = [
    {
        displayName: "Jurisdiction",
        options: [
            {
                displayValue: "Federal",
                value: "Federal"
            },
            {
                displayValue: "New South Wales",
                value: "New South Wales"
            },
            {
                displayValue: "Victoria",
                value: "Victoria"
            },
            {
                displayValue: "Queensland",
                value: "Queensland"
            },
            {
                displayValue: "Western Australia",
                value: "Western Australia"
            },
            {
                displayValue: "South Australia",
                value: "South Australia"
            },
            {
                displayValue: "Australian Capital Territory",
                value: "Australian Capital Territory"
            },
            {
                displayValue: "Tasmania",
                value: "Tasmania"
            }
        ],
        fieldName: "jurisdiction"
    },
    {
        displayName: "Document Type",
        options: [
            {
                displayValue: "Judgment",
                value: "judgement"
            },
            {
                displayValue: "Legislation",
                value: "legislation"
            },
            {
                displayValue: "The Constitution",
                value: "constitution"
            }
        ],
        fieldName: "doc_type"
    },
    {
        displayName: "Court",
        reliesOn: { key: "doc_type", value: "judgement" },
        type: "search",
        options: [
            {
                displayValue: "High Court of Australia",
                value: "High Court of Australia",
                group: "Federal"
            },
            {
                displayValue: "Federal Court of Australia",
                value: "Federal Court of Australia",
                group: "Federal"
            },
            {
                displayValue: "Family Court of Australia",
                value: "Family Court of Australia",
                group: "Federal"
            },
            {
                displayValue: "Federal Circuit and Family Court of Australia - D1 Appellate",
                value: "Federal Circuit and Family Court of Australia - Division 1 Appellate Jurisdiction",
                group: "Federal"
            },
            {
                displayValue: "Federal Circuit and Family Court of Australia - D1 First Instance",
                value: "Federal Circuit and Family Court of Australia - Division 1 First Instance",
                group: "Federal"
            },
            {
                displayValue: "Federal Circuit and Family Court of Australia - D2 Family Law",
                value: "Federal Circuit and Family Court of Australia - Division 2 Family Law",
                group: "Federal"
            },
            {
                displayValue: "Federal Circuit and Family Court of Australia - D2 General Federal Law",
                value: "Federal Circuit and Family Court of Australia - Division 2 General Federal Law",
                group: "Federal"
            },
            {
                displayValue: "Supreme Court New South Wales",
                value: "Supreme Court New South Wales",
                group: "New South Wales"
            },
            {
                displayValue: "District Court New South Wales",
                value: "District Court New South Wales",
                group: "New South Wales"
            },
            {
                displayValue: "Land and Environment Court New South Wales",
                value: "Land and Environment Court New South Wales",
                group: "New South Wales"
            },
            {
                displayValue: "Civil and Administrative Tribunal New South Wales",
                value: "Civil and Administrative Tribunal New South Wales",
                group: "New South Wales"
            },
            {
                displayValue: "Court of Appeal New South Wales",
                value: "Court of Appeal New South Wales",
                group: "New South Wales"
            },
            {
                displayValue: "Court of Criminal Appeal New South Wales",
                value: "Court of Criminal Appeal New South Wales",
                group: "New South Wales"
            },
            {
                displayValue: "Industrial Relations Commission New South Wales",
                value: "Industrial Relations Commission New South Wales",
                group: "New South Wales"
            },
            {
                displayValue: "Supreme Court of Victoria",
                value: "Supreme Court of Victoria",
                group: "Victoria"
            },
            {
                displayValue: "County Court Victoria",
                value: "County Court Victoria",
                group: "Victoria"
            },
            {
                displayValue: "Supreme Court of Victoria - Court of Appeal",
                value: "Supreme Court of Victoria - Court of Appeal",
                group: "Victoria"
            },
            {
                displayValue: "Magistrates' Court of Victoria",
                value: "Magistrates' Court of Victoria",
                group: "Victoria"
            },
            {
                displayValue: "Coroners Court of Victoria",
                value: "Coroners Court of Victoria",
                group: "Victoria"
            },
            {
                displayValue: "Supreme Court of Queensland",
                value: "Supreme Court of Queensland",
                group: "Queensland"
            },
            {
                displayValue: "Supreme Court of Queensland - Court of Appeal",
                value: "Supreme Court of Queensland - Court of Appeal",
                group: "Queensland"
            },
            {
                displayValue: "Queensland District Court Decisions",
                value: "Queensland District Court Decisions",
                group: "Queensland"
            },
            {
                displayValue: "Queensland Planning and Environment Court Decisions",
                value: "Queensland Planning and Environment Court Decisions",
                group: "Queensland"
            },
            {
                displayValue: "Coroners Court of Queensland",
                value: "Coroners Court of Queensland",
                group: "Queensland"
            },
            {
                displayValue: "Land Court of Queensland",
                value: "Land Court of Queensland",
                group: "Queensland"
            },
            {
                displayValue: "Childrens Court of Queensland",
                value: "Childrens Court of Queensland",
                group: "Queensland"
            },
            {
                displayValue: "Magistrates Court of Queensland",
                value: "Magistrates Court of Queensland",
                group: "Queensland"
            },
            {
                displayValue: "Industrial Court of Queensland",
                value: "Industrial Court of Queensland",
                group: "Queensland"
            },
            {
                displayValue: "Queensland Mental Health Court",
                value: "Queensland Mental Health Court",
                group: "Queensland"
            },
            {
                displayValue: "Land Appeal Court of Queensland",
                value: "Land Appeal Court of Queensland",
                group: "Queensland"
            },
            {
                displayValue: "Childrens Court of Queensland (Magistrates)",
                value: "Childrens Court of Queensland (Magistrates)",
                group: "Queensland"
            },
            {
                displayValue: "Supreme Court of Western Australia",
                value: "Supreme Court of Western Australia",
                group: "Western Australia"
            },
            {
                displayValue: "Supreme Court of Western Australia - Court of Appeal",
                value: "Supreme Court of Western Australia - Court of Appeal",
                group: "Western Australia"
            },
            {
                displayValue: "District Court of Western Australia",
                value: "District Court of Western Australia",
                group: "Western Australia"
            },
            {
                displayValue: "Coroners Court of Western Australia",
                value: "Coroners Court of Western Australia",
                group: "Western Australia"
            },
            {
                displayValue: "Family Court of Western Australia",
                value: "Family Court of Western Australia",
                group: "Western Australia"
            },
            {
                displayValue: "Family Court of Western Australia - Magistrates Decisions",
                value: "Family Court of Western Australia - Magistrates Decisions",
                group: "Western Australia"
            },
            {
                displayValue: "Supreme Court of South Australia",
                value: "Supreme Court of South Australia",
                group: "South Australia"
            },
            {
                displayValue: "District Court of South Australia",
                value: "District Court of South Australia",
                group: "South Australia"
            },
            {
                displayValue: "Supreme Court of South Australia - Full Court",
                value: "Supreme Court of South Australia - Full Court",
                group: "South Australia"
            },
            {
                displayValue: "Supreme Court of South Australia - Court of Appeal",
                value: "Supreme Court of South Australia - Court of Appeal",
                group: "South Australia"
            },
            {
                displayValue: "South Australian Employment Tribunal",
                value: "South Australian Employment Tribunal",
                group: "South Australia"
            },
            {
                displayValue: "Environment Resources and Development Court of South Australia",
                value: "Environment Resources and Development Court of South Australia",
                group: "South Australia"
            },
            {
                displayValue: "South Australian Industrial Relations Court",
                value: "South Australian Industrial Relations Court",
                group: "South Australia"
            },

            {
                displayValue: "Coroners Court of South Australia",
                value: "Coroners Court of South Australia",
                group: "South Australia"
            },
            {
                displayValue: "Licensing Court of South Australia",
                value: "Licensing Court of South Australia",
                group: "South Australia"
            },
            {
                displayValue: "Supreme Court of Tasmania",
                value: "Supreme Court of Tasmania",
                group: "Tasmania"
            },
            {
                displayValue: "Supreme Court of Tasmania - Court of Criminal Appeal",
                value: "Supreme Court of Tasmania - Court of Criminal Appeal",
                group: "Tasmania"
            },
            {
                displayValue: "Supreme Court of Tasmania - Full Court",
                value: "Supreme Court of Tasmania - Full Court",
                group: "Tasmania"
            },
            {
                displayValue: "Tasmanian Coroners Court",
                value: "Tasmanian Coroners Court",
                group: "Tasmania"
            },
            {
                displayValue: "Magistrates Court of Tasmania",
                value: "Magistrates Court of Tasmania",
                group: "Tasmania"
            },
            {
                displayValue: "Resource Management and Planning Appeal Tribunal of Tasmania",
                value: "Resource Management and Planning Appeal Tribunal of Tasmania",
                group: "Tasmania"
            },
            {
                displayValue: "Anti-Discrimination Tribunal of Tasmania",
                value: "Anti-Discrimination Tribunal of Tasmania",
                group: "Tasmania"
            },
            {
                displayValue: "Legal Profession Disciplinary Tribunal of Tasmania",
                value: "Legal Profession Disciplinary Tribunal of Tasmania",
                group: "Tasmania"
            },
            {
                displayValue: "Forest Practices Tribunal of Tasmania",
                value: "Forest Practices Tribunal of Tasmania",
                group: "Tasmania"
            },
            {
                displayValue: "Supreme Court of the ACT",
                value: "Supreme Court of the ACT",
                group: "Australian Capital Territory"
            },
            {
                displayValue: "Supreme Court of the Australian Capital Territory",
                value: "Supreme Court of the Australian Capital Territory",
                group: "Australian Capital Territory"
            },
            {
                displayValue: "Supreme Court of the ACT - Court of Appeal",
                value: "Supreme Court of the ACT - Court of Appeal",
                group: "Australian Capital Territory"
            },
            {
                displayValue: "Supreme Court of the Australian Capital Territory - Court of Appeal",
                value: "Supreme Court of the Australian Capital Territory - Court of Appeal",
                group: "Australian Capital Territory"
            },
            {
                displayValue: "Supreme Court of the Australian Capital Territory - Full Court",
                value: "Supreme Court of the Australian Capital Territory - Full Court",
                group: "Australian Capital Territory"
            },
            {
                displayValue: "ACT Ombudsman - Freedom of Information",
                value: "ACT Ombudsman - Freedom of Information",
                group: "Australian Capital Territory"
            },
            {
                displayValue: "Magistrates Court of the Australian Capital Territory",
                value: "Magistrates Court of the Australian Capital Territory",
                group: "Australian Capital Territory"
            },
            {
                displayValue: "Coroners Court of the Australian Capital Territory",
                value: "Coroners Court of the Australian Capital Territory",
                group: "Australian Capital Territory"
            }
        ],
        fieldName: "court",
        filterBy: "jurisdiction"
    },
    {
        displayName: "Categories",
        options: [
            { displayValue: "Antitrust Compensation", value: "Antitrust Compensation" },
            { displayValue: "Administrative Law", value: "Administrative Law" },
            { displayValue: "Admiralty and Maritime Law", value: "Admiralty and Maritime Law" },
            { displayValue: "Adoption", value: "Adoption" },
            { displayValue: "Agency", value: "Agency" },
            { displayValue: "Agricultural Tenancy", value: "Agricultural Tenancy" },
            { displayValue: "Alternative Dispute Resolution", value: "Alternative Dispute Resolution" },
            { displayValue: "Animals", value: "Animals" },
            { displayValue: "Arbitration Law", value: "Arbitration Law" },
            { displayValue: "Aviation", value: "Aviation" },
            { displayValue: "Bailment", value: "Bailment" },
            { displayValue: "Banking and Finance", value: "Banking and Finance" },
            { displayValue: "Bankruptcy", value: "Bankruptcy" },
            { displayValue: "Building and Construction", value: "Building and Construction" },
            { displayValue: "Civil Law", value: "Civil Law" },
            { displayValue: "Commercial", value: "Commercial" },
            { displayValue: "Communications, Media and Defamation", value: "Communications, Media and Defamation" },
            { displayValue: "Compensation", value: "Compensation" },
            { displayValue: "Competition", value: "Competition" },
            { displayValue: "Conflict of Laws", value: "Conflict of Laws" },
            { displayValue: "Constitutional Law", value: "Constitutional Law" },
            { displayValue: "Consumer", value: "Consumer" },
            { displayValue: "Consumer Credit", value: "Consumer Credit" },
            { displayValue: "Consumer Law & Protection", value: "Consumer Law & Protection" },
            { displayValue: "Contract", value: "Contract" },
            { displayValue: "Coronial Inquest", value: "Coronial Inquest" },
            { displayValue: "Corporations and Associations", value: "Corporations and Associations" },
            { displayValue: "Costs", value: "Costs" },
            { displayValue: "Courts and Judges", value: "Courts and Judges" },
            { displayValue: "Criminal Law and Procedure", value: "Criminal Law and Procedure" },
            { displayValue: "Cultural Heritage and Art", value: "Cultural Heritage and Art" },
            { displayValue: "Customs and Excise", value: "Customs and Excise" },
            { displayValue: "Damages", value: "Damages" },
            { displayValue: "Defence and War", value: "Defence and War" },
            { displayValue: "Diplomatic Immunity", value: "Diplomatic Immunity" },
            { displayValue: "Discrimination and Equal Opportunity", value: "Discrimination and Equal Opportunity" },
            { displayValue: "Education and Training", value: "Education and Training" },
            { displayValue: "Employment, Labour and Industrial", value: "Employment, Labour and Industrial" },
            { displayValue: "Energy and Resources", value: "Energy and Resources" },
            { displayValue: "Environment and Planning", value: "Environment and Planning" },
            { displayValue: "Equity", value: "Equity" },
            { displayValue: "Ethics and Professional Regulation", value: "Ethics and Professional Regulation" },
            { displayValue: "Evidence", value: "Evidence" },
            { displayValue: "Extradition", value: "Extradition" },
            { displayValue: "Family", value: "Family" },
            { displayValue: "Family and Child Welfare", value: "Family and Child Welfare" },
            { displayValue: "Freedom of Information and Privacy", value: "Freedom of Information and Privacy" },
            { displayValue: "Gaming", value: "Gaming" },
            { displayValue: "Guardianship", value: "Guardianship" },
            { displayValue: "Health and Medical Law", value: "Health and Medical Law" },
            { displayValue: "Human Rights", value: "Human Rights" },
            { displayValue: "Industrial Law", value: "Industrial Law" },
            { displayValue: "Insolvency", value: "Insolvency" },
            { displayValue: "Insurance", value: "Insurance" },
            { displayValue: "Intellectual Property", value: "Intellectual Property" },
            { displayValue: "Land and Property", value: "Land and Property" },
            { displayValue: "Landlords failure to repair", value: "Landlords failure to repair" },
            { displayValue: "Licensing", value: "Licensing" },
            { displayValue: "Local Government", value: "Local Government" },
            { displayValue: "Mediation", value: "Mediation" },
            { displayValue: "Mental Health", value: "Mental Health" },
            { displayValue: "Migration", value: "Migration" },
            { displayValue: "Migration and Citizenship", value: "Migration and Citizenship" },
            { displayValue: "Mortgages and Securities", value: "Mortgages and Securities" },
            { displayValue: "Native Title", value: "Native Title" },
            { displayValue: "Negligence", value: "Negligence" },
            { displayValue: "Parliamentary Elections", value: "Parliamentary Elections" },
            { displayValue: "Partnership", value: "Partnership" },
            { displayValue: "Personal Property", value: "Personal Property" },
            { displayValue: "Power of Attorney", value: "Power of Attorney" },
            { displayValue: "Practice and Procedure", value: "Practice and Procedure" },
            { displayValue: "Primary Industries", value: "Primary Industries" },
            { displayValue: "Private International Law", value: "Private International Law" },
            { displayValue: "Proceeds of Crime", value: "Proceeds of Crime" },
            { displayValue: "Public International Law", value: "Public International Law" },
            { displayValue: "Racing Act", value: "Racing Act" },
            { displayValue: "Remedies", value: "Remedies" },
            { displayValue: "Residential Tenancy", value: "Residential Tenancy" },
            { displayValue: "Restitution", value: "Restitution" },
            { displayValue: "Retail Lease", value: "Retail Lease" },
            { displayValue: "Social Security", value: "Social Security" },
            { displayValue: "Sports Law", value: "Sports Law" },
            { displayValue: "Statutory Interpretation", value: "Statutory Interpretation" },
            { displayValue: "Succession", value: "Succession" },
            { displayValue: "Wills and Probate", value: "Wills and Probate" },
            { displayValue: "Superannuation", value: "Superannuation" },
            { displayValue: "Taxation and Revenue", value: "Taxation and Revenue" },
            { displayValue: "Termination of Residential Tenancy", value: "Termination of Residential Tenancy" },
            { displayValue: "Torts", value: "Torts" },
            { displayValue: "Trade and Commerce", value: "Trade and Commerce" },
            { displayValue: "Trade Practices", value: "Trade Practices" },
            { displayValue: "Traffic Law", value: "Traffic Law" },
            { displayValue: "Transportation", value: "Transportation" },
            { displayValue: "Trusts", value: "Trusts" },
            { displayValue: "Words and Phrases", value: "Words and Phrases" },
            { displayValue: "Workers’ Compensation", value: "Workers’ Compensation" }
        ],
        fieldName: "categories",
        searchable: true
    }
];

export const FILTERS_NZ: Filter[] = [
    {
        displayName: "Document Type",
        options: [
            {
                displayValue: "Judgment",
                value: "judgement"
            },
            {
                displayValue: "Legislation",
                value: "legislation"
            }
        ],
        fieldName: "doc_type"
    },
    {
        displayName: "Court",
        reliesOn: { key: "doc_type", value: "judgement" },
        type: "search",
        options: [],
        fieldName: "court"
    },
    {
        displayName: "Categories",
        options: [
            { displayValue: "Antitrust Compensation", value: "Antitrust Compensation" },
            { displayValue: "Administrative Law", value: "Administrative Law" },
            { displayValue: "Admiralty and Maritime Law", value: "Admiralty and Maritime Law" },
            { displayValue: "Adoption", value: "Adoption" },
            { displayValue: "Agency", value: "Agency" },
            { displayValue: "Agricultural Tenancy", value: "Agricultural Tenancy" },
            { displayValue: "Alternative Dispute Resolution", value: "Alternative Dispute Resolution" },
            { displayValue: "Animals", value: "Animals" },
            { displayValue: "Arbitration Law", value: "Arbitration Law" },
            { displayValue: "Aviation", value: "Aviation" },
            { displayValue: "Bailment", value: "Bailment" },
            { displayValue: "Banking and Finance", value: "Banking and Finance" },
            { displayValue: "Bankruptcy", value: "Bankruptcy" },
            { displayValue: "Building and Construction", value: "Building and Construction" },
            { displayValue: "Civil Law", value: "Civil Law" },
            { displayValue: "Commercial", value: "Commercial" },
            { displayValue: "Communications, Media and Defamation", value: "Communications, Media and Defamation" },
            { displayValue: "Compensation", value: "Compensation" },
            { displayValue: "Competition", value: "Competition" },
            { displayValue: "Conflict of Laws", value: "Conflict of Laws" },
            { displayValue: "Constitutional Law", value: "Constitutional Law" },
            { displayValue: "Consumer", value: "Consumer" },
            { displayValue: "Consumer Credit", value: "Consumer Credit" },
            { displayValue: "Consumer Law & Protection", value: "Consumer Law & Protection" },
            { displayValue: "Contract", value: "Contract" },
            { displayValue: "Coronial Inquest", value: "Coronial Inquest" },
            { displayValue: "Corporations and Associations", value: "Corporations and Associations" },
            { displayValue: "Costs", value: "Costs" },
            { displayValue: "Courts and Judges", value: "Courts and Judges" },
            { displayValue: "Criminal Law and Procedure", value: "Criminal Law and Procedure" },
            { displayValue: "Cultural Heritage and Art", value: "Cultural Heritage and Art" },
            { displayValue: "Customs and Excise", value: "Customs and Excise" },
            { displayValue: "Damages", value: "Damages" },
            { displayValue: "Defence and War", value: "Defence and War" },
            { displayValue: "Diplomatic Immunity", value: "Diplomatic Immunity" },
            { displayValue: "Discrimination and Equal Opportunity", value: "Discrimination and Equal Opportunity" },
            { displayValue: "Education and Training", value: "Education and Training" },
            { displayValue: "Employment, Labour and Industrial", value: "Employment, Labour and Industrial" },
            { displayValue: "Energy and Resources", value: "Energy and Resources" },
            { displayValue: "Environment and Planning", value: "Environment and Planning" },
            { displayValue: "Equity", value: "Equity" },
            { displayValue: "Ethics and Professional Regulation", value: "Ethics and Professional Regulation" },
            { displayValue: "Evidence", value: "Evidence" },
            { displayValue: "Extradition", value: "Extradition" },
            { displayValue: "Family", value: "Family" },
            { displayValue: "Family and Child Welfare", value: "Family and Child Welfare" },
            { displayValue: "Freedom of Information and Privacy", value: "Freedom of Information and Privacy" },
            { displayValue: "Gaming", value: "Gaming" },
            { displayValue: "Guardianship", value: "Guardianship" },
            { displayValue: "Health and Medical Law", value: "Health and Medical Law" },
            { displayValue: "Human Rights", value: "Human Rights" },
            { displayValue: "Industrial Law", value: "Industrial Law" },
            { displayValue: "Insolvency", value: "Insolvency" },
            { displayValue: "Insurance", value: "Insurance" },
            { displayValue: "Intellectual Property", value: "Intellectual Property" },
            { displayValue: "Land and Property", value: "Land and Property" },
            { displayValue: "Landlords failure to repair", value: "Landlords failure to repair" },
            { displayValue: "Licensing", value: "Licensing" },
            { displayValue: "Local Government", value: "Local Government" },
            { displayValue: "Mediation", value: "Mediation" },
            { displayValue: "Mental Health", value: "Mental Health" },
            { displayValue: "Migration", value: "Migration" },
            { displayValue: "Migration and Citizenship", value: "Migration and Citizenship" },
            { displayValue: "Mortgages and Securities", value: "Mortgages and Securities" },
            { displayValue: "Native Title", value: "Native Title" },
            { displayValue: "Negligence", value: "Negligence" },
            { displayValue: "Parliamentary Elections", value: "Parliamentary Elections" },
            { displayValue: "Partnership", value: "Partnership" },
            { displayValue: "Personal Property", value: "Personal Property" },
            { displayValue: "Power of Attorney", value: "Power of Attorney" },
            { displayValue: "Practice and Procedure", value: "Practice and Procedure" },
            { displayValue: "Primary Industries", value: "Primary Industries" },
            { displayValue: "Private International Law", value: "Private International Law" },
            { displayValue: "Proceeds of Crime", value: "Proceeds of Crime" },
            { displayValue: "Public International Law", value: "Public International Law" },
            { displayValue: "Racing Act", value: "Racing Act" },
            { displayValue: "Remedies", value: "Remedies" },
            { displayValue: "Residential Tenancy", value: "Residential Tenancy" },
            { displayValue: "Restitution", value: "Restitution" },
            { displayValue: "Retail Lease", value: "Retail Lease" },
            { displayValue: "Social Security", value: "Social Security" },
            { displayValue: "Sports Law", value: "Sports Law" },
            { displayValue: "Statutory Interpretation", value: "Statutory Interpretation" },
            { displayValue: "Succession", value: "Succession" },
            { displayValue: "Wills and Probate", value: "Wills and Probate" },
            { displayValue: "Superannuation", value: "Superannuation" },
            { displayValue: "Taxation and Revenue", value: "Taxation and Revenue" },
            { displayValue: "Termination of Residential Tenancy", value: "Termination of Residential Tenancy" },
            { displayValue: "Torts", value: "Torts" },
            { displayValue: "Trade and Commerce", value: "Trade and Commerce" },
            { displayValue: "Trade Practices", value: "Trade Practices" },
            { displayValue: "Traffic Law", value: "Traffic Law" },
            { displayValue: "Transportation", value: "Transportation" },
            { displayValue: "Trusts", value: "Trusts" },
            { displayValue: "Words and Phrases", value: "Words and Phrases" },
            { displayValue: "Workers’ Compensation", value: "Workers’ Compensation" }
        ],
        fieldName: "categories",
        searchable: true
    }
];

export interface Country {
    code: string;
    name: string;
    enabled: boolean;
}

export const COUNTRIES: Country[] = [
    { code: "au", name: "Australia", enabled: true },
    { code: "nz", name: "New Zealand", enabled: true },
    { code: "ca", name: "Canada", enabled: false }
];

export const FILTERS_CA: Filter[] = [
    // Filters for Canada
    // ...
];

export function getFiltersByCountry(countryCode: string): Filter[] {
    switch (countryCode) {
        case "au":
            return FILTERS_AU;
        case "nz":
            return FILTERS_NZ;
        case "ca":
            return FILTERS_CA;
        default:
            return []; // Return an empty array if the country code is not recognized
    }
}
