import * as React from "react";

function StripePricingTable({ userEmail }: { userEmail: string }) {
    // Paste the stripe-pricing-table snippet in your React component
    return (
        <stripe-pricing-table
            pricing-table-id={import.meta.env.DEV ? "prctbl_1PS9StCwUGq9odu69hJ03sCD" : "prctbl_1OyRE7CwUGq9odu6vLNY9RCe"}
            publishable-key={
                import.meta.env.DEV
                    ? "pk_test_51OE3H2CwUGq9odu6vFzKSniNuooMHtyjIUcNkZGSOXACrCYAqVIHy1SpV8bOjE6koXdiOfB1sOBrv9KUefNDS8JD00vZLHvL11"
                    : "pk_live_51OE3H2CwUGq9odu6bFTIO1PTYpFq7v1L4TI8zyqjZulAjAebhwVk7gjzqSC71nK0VXx4oyCZZm1Roe47S1oTNcfP001N0MNeoj"
            }
            customer-email={userEmail}
        ></stripe-pricing-table>
    );
}

export default StripePricingTable;
declare global {
    // eslint-disable-next-line
    namespace JSX {
        interface IntrinsicElements {
            "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}
