import { Example } from "./Example";

import styles from "./Example.module.css";
import { useContext } from "react";
import { CountryContext } from "../../contexts/CountryContext";
import { Box } from "@mui/joy";
import { isMobile } from "../../util/utils";

export interface ExampleModel {
    text: string;
    value: string;
}

const EXAMPLES_AU: ExampleModel[] = [
    {
        text: "Possible defences for violating the biofuels act",
        value: "Possible defences for violating the biofuels act"
    },
    {
        text: "Show me cases where the principle of 'duty of care' in negligence was significantly elaborated upon",
        value: "Show me cases where the principle of 'duty of care' in negligence was significantly elaborated upon"
    },
    {
        text: "What legislation is relevant for the protection of the Great Barrier Reef?",
        value: "What legislation is relevant for the protection of the Great Barrier Reef?"
    },
    {
        text: "What are likely outcomes in court for companies who mislead customers on TV",
        value: "What are likely outcomes in court for companies who mislead customers on TV"
    },
    {
        text: "Which cases cover environmental law disputes in NSW",
        value: "Which cases cover environmental law disputes in NSW"
    },
    {
        text: "What are the key provisions of the Airports (Environment Protection) Regulations 1997?",
        value: "What are the key provisions of the Airports (Environment Protection) Regulations 1997?"
    },
    {
        text: "Show me cases where the principle of 'duty of care' in negligence was significantly elaborated upon",
        value: "Show me cases where the principle of 'duty of care' in negligence was significantly elaborated upon"
    },
    {
        text: "How do Australian courts handle cases on Indigenous land rights?",
        value: "How do Australian courts handle cases on Indigenous land rights?"
    },
    {
        text: "What are the penalties for drug possession in Australia",
        value: "What are the penalties for drug possession in Australia"
    },
    {
        text: "Provide examples of common restrictions on the transfer of shares outlined in Australian shareholders' agreements",
        value: "Provide examples of common restrictions on the transfer of shares outlined in Australian shareholders' agreements"
    },
    {
        text: "What are employer obligations under Australian workplace safety laws?",
        value: "What are employer obligations under Australian workplace safety laws?"
    },
    {
        text: "Can you list cases regarding constitutional challenges in Australian immigration law?",
        value: "Can you list cases regarding constitutional challenges in Australian immigration law?"
    },
    {
        text: "What are Australian environmental protection regulations?",
        value: "What are Australian environmental protection regulations?"
    },
    {
        text: "Find cases addressing corporate governance in Australian financial markets.",
        value: "Find cases addressing corporate governance in Australian financial markets."
    },
    {
        text: "Display cases involving the application of Australian anti-discrimination laws.",
        value: "Display cases involving the application of Australian anti-discrimination laws."
    },
    {
        text: "Can you list cases on intellectual property disputes under Australian law?",
        value: "Can you list cases on intellectual property disputes under Australian law?"
    },
    {
        text: "Show precedent-setting cases related to Australian contract law interpretations.",
        value: "Show precedent-setting cases related to Australian contract law interpretations."
    }
];

const EXAMPLES_NZ: ExampleModel[] = [
    {
        text: "Possible defences for violating the Biofuel Bill",
        value: "Possible defences for violating the Biofuel Bill"
    },
    {
        text: "Show me cases where the principle of 'duty of care' in negligence was significantly elaborated upon in New Zealand",
        value: "Show me cases where the principle of 'duty of care' in negligence was significantly elaborated upon in New Zealand"
    },
    {
        text: "What legislation is relevant for the protection of New Zealand's marine reserves?",
        value: "What legislation is relevant for the protection of New Zealand's marine reserves?"
    },
    {
        text: "What are likely outcomes in court for companies who mislead customers in advertisements in New Zealand?",
        value: "What are likely outcomes in court for companies who mislead customers in advertisements in New Zealand?"
    },
    {
        text: "Which cases cover environmental law disputes in New Zealand?",
        value: "Which cases cover environmental law disputes in New Zealand?"
    },
    {
        text: "What are the key provisions of the Airport Authorities Act 1966 regarding environmental protection?",
        value: "What are the key provisions of the Airport Authorities Act 1966 regarding environmental protection?"
    },
    {
        text: "How do New Zealand courts handle cases on Māori land rights?",
        value: "How do New Zealand courts handle cases on Māori land rights?"
    },
    {
        text: "What are the penalties for drug possession in New Zealand?",
        value: "What are the penalties for drug possession in New Zealand?"
    },
    {
        text: "Provide examples of common restrictions on the transfer of shares outlined in New Zealand shareholders' agreements",
        value: "Provide examples of common restrictions on the transfer of shares outlined in New Zealand shareholders' agreements"
    },
    {
        text: "What are employer obligations under New Zealand workplace safety laws?",
        value: "What are employer obligations under New Zealand workplace safety laws?"
    },
    {
        text: "Can you list cases regarding constitutional challenges in New Zealand immigration law?",
        value: "Can you list cases regarding constitutional challenges in New Zealand immigration law?"
    },
    {
        text: "What are New Zealand environmental protection regulations?",
        value: "What are New Zealand environmental protection regulations?"
    },
    {
        text: "Find cases addressing corporate governance in New Zealand financial markets.",
        value: "Find cases addressing corporate governance in New Zealand financial markets."
    },
    {
        text: "Display cases involving the application of New Zealand anti-discrimination laws.",
        value: "Display cases involving the application of New Zealand anti-discrimination laws."
    },
    {
        text: "Can you list cases on intellectual property disputes under New Zealand law?",
        value: "Can you list cases on intellectual property disputes under New Zealand law?"
    },
    {
        text: "Show precedent-setting cases related to New Zealand contract law interpretations.",
        value: "Show precedent-setting cases related to New Zealand contract law interpretations."
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    const { country } = useContext(CountryContext);

    const examples = (country?.code === "nz" ? EXAMPLES_NZ : EXAMPLES_AU).sort(() => Math.random() - 0.5);

    return (
        <Box
            sx={{
                scroll: "none",
                overflowX: "hidden",
                maxWidth: isMobile() ? "99vw" : "80vw",
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: "100px" /* Width of the left fade-out */,
                    background: "linear-gradient(to right, rgba(242,242,242,1) 10%, rgba(255,255,255,0))",
                    pointerEvents: "none",
                    zIndex: 1
                },
                "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "200px" /* Width of the right fade-out */,
                    background: "linear-gradient(to left, rgba(242,242,242,1), rgba(255,255,255,0))",
                    pointerEvents: "none",
                    zIndex: 1
                }
            }}
        >
            {" "}
            <div className={styles.scrollContent}>
                {examples.map((x, i) => (
                    <div className={styles.item} key={i}>
                        <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                    </div>
                ))}
            </div>
        </Box>
    );
};
