import mixpanel from "mixpanel-browser";
import { Document } from "../api";

export function isMobile(): boolean {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // You can adjust the breakpoint according to your needs
    const mobileBreakpoint = 768;
    return screenWidth < mobileBreakpoint;
}

export function recordEvent(eventName: string, eventProperties: object): void {
    mixpanel.track(eventName, eventProperties);
}

export type DateOptions =
    | {
          year: "numeric" | "2-digit" | undefined;
          month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
          day: "numeric" | "2-digit" | undefined;
      }
    | undefined;

export function getDate(doc: Document, options: DateOptions = undefined): string {
    const date = doc.decision_date || doc.date_of_decision || doc.date;
    if (!date) {
        return "";
    }

    return new Date(date).toLocaleDateString("en-AU", options);
}

export const animations: string[] = [];

export const removeDuplicates = <T>(arrayWithDuplicates: T[], keyToFilter: keyof T): T[] => {
    const uniqueIds = new Set<T[keyof T]>();
    const uniqueArray = arrayWithDuplicates.filter(item => {
        if (!uniqueIds.has(item[keyToFilter])) {
            uniqueIds.add(item[keyToFilter]);
            return true;
        }
        return false;
    });
    return uniqueArray;
};

export const isPDF = (url: string): boolean => {
    return url?.toLowerCase().endsWith("pdf") || url?.toLowerCase().includes("/pdf/");
};
