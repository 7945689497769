import { Box, Chip } from "@mui/joy";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { recordEvent } from "../../util/utils";

export const ChatTypeSelector = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const currentChatType = searchParams.get("chatType") || "chat";
    const [selected, setSelected] = React.useState<string>(currentChatType);

    const updateChatType = (chatType: string) => {
        recordEvent("chat_type_selected", { chatType });
        searchParams.set("chatType", chatType);
        navigate({ ...location, search: searchParams.toString() });
        setSelected(chatType);
    };
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "left",
                gap: "5px",
                paddingBottom: "10px"
            }}
        >
            <Chip onClick={() => updateChatType("chat")} color={selected == "chat" ? "primary" : "neutral"} variant={selected == "chat" ? "solid" : "outlined"}>
                Chat
            </Chip>
            <Chip onClick={() => updateChatType("find")} color={selected == "find" ? "primary" : "neutral"} variant={selected == "find" ? "solid" : "outlined"}>
                Search
            </Chip>
        </Box>
    );
};
