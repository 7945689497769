import React, { useRef, useEffect } from "react";
import { Box, Button, Typography } from "@mui/joy";
import { PDFViewer, PDFViewerHandle } from "../../components/PDFViewer/PDFViewer";
import { QuestionInput } from "../../components/QuestionInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserChatMessage } from "../../components/UserChatMessage";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { Highlight, Quote } from "./types";
import { ChatAppResponse } from "../../api";

interface PDFChatViewProps {
    pdfUrl: string;
    documentId: string;
    onClose: () => void;
    handleSendQuestion: (question: string) => void;
    answers: [string, ChatAppResponse][];
    isLoading: boolean;
    // eslint-disable-next-line
    error: any;
    quotes: Quote[];
    lastQuestionRef: React.MutableRefObject<string>;
}

export const PDFChatView: React.FC<PDFChatViewProps> = ({ pdfUrl, onClose, handleSendQuestion, answers, isLoading, error, quotes, lastQuestionRef }) => {
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const pdfViewerRef = useRef<PDFViewerHandle | null>(null);

    const handleQuoteClick = (quote: Quote) => {
        const firstHighlight: Highlight = quote.highlights[0];
        if (firstHighlight) {
            const area = {
                pageIndex: firstHighlight.pageIndex, // Assuming page_num is 1-based
                height: firstHighlight.height,
                left: firstHighlight.left,
                top: Math.max(firstHighlight.top - 15, 0),
                width: firstHighlight.width
            };
            if (pdfViewerRef.current) pdfViewerRef.current.jumpToHighlightArea(area);
        }
    };

    useEffect(() => {
        if (isLoading) {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        } else {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [answers, isLoading]);

    return (
        <Box sx={{ display: "flex", height: "100vh", width: "100%" }}>
            <Box
                sx={{
                    width: "50%",
                    maxWidth: "50%",
                    p: 2,
                    borderRight: "1px solid #ccc",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "top", gap: "15px" }}>
                    <Button onClick={onClose} startDecorator={<ArrowBackIcon />} size="sm" variant={"outlined"} sx={{ alignSelf: "flex-start", mb: 2 }}>
                        Back
                    </Button>
                    <Typography level="h4">Chat with this document</Typography>
                </Box>

                <QuestionInput placeholder="Type your question here" onSend={handleSendQuestion} clearOnSend />
                <div style={{ flex: 1, overflowY: "auto" }}>
                    {answers.map((answer, index) => (
                        <div key={index}>
                            <UserChatMessage message={answer[0]} />
                            <div>
                                <Answer
                                    isStreaming={false}
                                    key={index}
                                    answer={answer[1]}
                                    chatType="pdf"
                                    isSelected={false}
                                    onCitationClicked={() => {}}
                                    onSupportingContentClicked={() => {}}
                                    onFollowupQuestionClicked={handleSendQuestion}
                                    showFollowupQuestions={true}
                                    onQuoteClick={handleQuoteClick}
                                />
                            </div>
                        </div>
                    ))}
                    {isLoading && (
                        <>
                            <UserChatMessage message={lastQuestionRef.current} />
                            <Box sx={{ maxWidth: "500px" }}>
                                <AnswerLoading gifsLoaded={true} />
                            </Box>
                        </>
                    )}
                    {error && (
                        <>
                            <UserChatMessage message={lastQuestionRef.current} />
                            <div>
                                <AnswerError error={error.toString()} onRetry={() => handleSendQuestion(lastQuestionRef.current)} />
                            </div>
                        </>
                    )}
                    <div ref={chatMessageStreamEnd} />
                </div>
            </Box>
            <Box sx={{ width: "50%", height: "100%", overflow: "hidden" }}>
                <PDFViewer fileUrl={pdfUrl} ref={pdfViewerRef} currentQuery={lastQuestionRef.current} quotes={quotes} />
            </Box>
        </Box>
    );
};
