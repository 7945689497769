import { Outlet, Link, useSearchParams, useParams } from "react-router-dom";
import styles from "./Layout.module.css";
import { Box, IconButton } from "@mui/joy";
import { Sidebar } from "../../components/Sidebar";
import { isMobile } from "../../util/utils";
import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/logo_b.png";
import { CountryContext } from "../../contexts/CountryContext";
import MenuIcon from "@mui/icons-material/Menu";
import { Authentication } from "../../components/Authentication";
import { useSwipeable } from "react-swipeable";

const Layout = () => {
    const [, setSearchParams] = useSearchParams();
    const { setCountryByCode } = useContext(CountryContext);
    const { countryCode = "au" } = useParams();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        setCountryByCode(countryCode);
    }, [countryCode]);

    const reloadPage = () => {
        setSearchParams(new URLSearchParams());
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (isMobile() && sidebarOpen) {
                setSidebarOpen(false);
            }
        },
        onSwipedRight: () => {
            if (isMobile() && !sidebarOpen) {
                setSidebarOpen(true);
            }
        }
    });

    return (
        <div {...swipeHandlers}>
            <div className={styles.layout}>
                <div className={styles.bottomBar}>
                    {isMobile() && (
                        <div className={styles.headerContainer}>
                            <IconButton onClick={toggleSidebar}>
                                <MenuIcon />
                            </IconButton>
                            <Link to={"/"} onClick={() => reloadPage()} className={styles.headerTitleContainer}>
                                <img className={styles.headerLogo} src={logo} />
                            </Link>
                            <div>
                                {" "}
                                <Authentication />
                            </div>
                        </div>
                    )}
                </div>
                <Box className={styles.body}>
                    {(!isMobile() || sidebarOpen) && <Sidebar sidebarOpen={!isMobile() || sidebarOpen} onClose={toggleSidebar} />}
                    <Outlet />
                </Box>
            </div>
        </div>
    );
};

export default Layout;
