// Component for sub detail card
import React, { useContext } from "react";
import { Button, Card, CardContent, Divider, Skeleton, Typography } from "@mui/joy";
import { UserContext } from "../../contexts/UserContext";
import { CheckoutModal } from "../Checkout";
import { ReferralModal } from "../ReferralModal/ReferralModal";

export const SubscriptionDetail = () => {
    const [open, setOpen] = React.useState(false);
    const { user } = useContext(UserContext);

    return (
        <Card>
            <CardContent>
                {user ? (
                    <>
                        <Typography fontSize="md" fontWeight="md">
                            Plan: {user.plan?.name}
                        </Typography>
                        <Typography level="body-sm">Queries left: {user.searches_left}</Typography>
                        <Typography level="body-sm">Resets: {user.reset_date}</Typography>
                    </>
                ) : (
                    <>
                        <Skeleton width={100} level={"body-md"} variant="text" />
                        <Skeleton width={130} level={"body-sm"} variant="text" />
                        <Skeleton width={85} level={"body-sm"} variant="text" />
                    </>
                )}
            </CardContent>
            <Divider />
            {user ? (
                <>
                    <Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
                        Upgrade
                    </Button>
                    <CheckoutModal isOpen={open} toggleModal={() => setOpen(!open)} userEmail={user.email} />
                    <ReferralModal />
                </>
            ) : (
                <Skeleton width={85} level={"body-md"} variant="text" />
            )}
        </Card>
    );
};
