import { Stack, PrimaryButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";

import styles from "./Answer.module.css";
import { CheckoutModal } from "../Checkout";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";

interface Props {
    error: string;
    onRetry: () => void;
}

export const AnswerError = ({ error, onRetry }: Props) => {
    const [openCheckout, setOpenCheckout] = useState<boolean>(error.includes("You are out of searches"));
    const { user } = useContext(UserContext);

    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

            <Stack.Item grow>
                <p className={styles.answerText}>{error}</p>
            </Stack.Item>

            <PrimaryButton className={styles.retryButton} onClick={onRetry} text="Retry" />
            <CheckoutModal isOpen={openCheckout} toggleModal={() => setOpenCheckout(!openCheckout)} userEmail={user?.email || ""} />
        </Stack>
    );
};
