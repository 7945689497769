import { Typography } from "@mui/joy";
import styles from "./SupportingContent.module.css";
import { Box, Button } from "@mui/joy";
import { getDate, isPDF } from "../../util/utils";
import { CaseNoteViewer } from "../CaseNoteViewer";
import { Launch } from "@mui/icons-material";
import { ChatPDFButton } from "../ChatPDFButton/ChatPDFButton";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";
import { Document } from "../../api";

interface Props {
    supportingContent: string[];
    documents: Document[];
}

function removeDuplicates<T>(arr: T[], key: keyof T): T[] {
    const seen = new Set();

    return arr.filter((item: T) => {
        const value = item[key];
        if (seen.has(value)) {
            return false;
        }
        seen.add(value);
        return true;
    });
}

export const SupportingContent = ({ documents }: Props) => {
    documents = removeDuplicates(documents, "url");

    return (
        <ul className={styles.supportingContentNavList}>
            {documents.map((document, i) => {
                return (
                    <li key={i} className={styles.supportingContentItem}>
                        <Box>
                            <Typography level="title-sm">{documents[i].name.replace("[frequently_cited]", "")}</Typography>
                            <Typography level="body-sm">
                                {getDate(documents[i])} {documents[i].category ? "| " + documents[i].category?.split(" ")[0] : null}{" "}
                                {documents[i].before ? "| Judgement of " + documents[i].before : null}
                            </Typography>
                            {documents[i].most_cited && <FrequentlyCitedLabel />}
                        </Box>
                        <Typography level="body-sm" variant="soft">
                            " {document.extract} "
                        </Typography>

                        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                            <Button
                                startDecorator={<Launch />}
                                variant="plain"
                                size="md"
                                component="a"
                                href={document.url}
                                target="_blank"
                                sx={{ textDecoration: "none" }}
                                rel="noopener noreferrer"
                            >
                                Original
                            </Button>
                            {document.summary1 && (
                                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                                    <CaseNoteViewer summary={JSON.parse(document.summary1)} />
                                </Box>
                            )}
                            {isPDF(document.pdf_url || document.url) && <ChatPDFButton document={document} />}
                        </Box>
                    </li>
                );
            })}
        </ul>
    );
};
