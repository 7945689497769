import React, { createContext, useState } from "react";
import { COUNTRIES, Country } from "../util/constants";

interface CountryContextType {
    country: Country;
    setCountryByCode: (code: string | undefined) => void;
}

export const CountryContext = createContext<CountryContextType>({
    country: COUNTRIES[0],
    setCountryByCode: () => console.warn("no country provider")
});

interface Props {
    children: React.ReactNode;
}

export const CountryProvider: React.FC<Props> = ({ children }) => {
    const [country, setCountry] = useState<Country>(COUNTRIES[0]);
    const setCountryByCode = (code: string | undefined) => {
        if (!code) {
            setCountry(COUNTRIES[0]);
            return;
        }
        setCountry(COUNTRIES.find(country => country.code === code) || COUNTRIES[0]);
    };

    return <CountryContext.Provider value={{ country, setCountryByCode }}>{children}</CountryContext.Provider>;
};
