import { Box, Button, Sheet, Table, Typography } from "@mui/joy";
import { useEffect } from "react";
import styles from "./ResultsTable.module.css";
import { Launch } from "@mui/icons-material";
import { DateOptions, getDate } from "../../util/utils";
import { Document } from "../../api";
import { CaseNoteViewer } from "../CaseNoteViewer";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";
import { ChatPDFButton } from "../ChatPDFButton";
import React from "react";

const dateOptions: DateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

interface Row {
    name: string;
    jurisdiction: string;
    date: string;
    type: string;
    url: string;
    extract: string;
    category: string | null;
    summary1?: string;
    summary_version?: number;
    most_cited?: boolean;
    pdf_url?: string;
    id: string;
    doc_id: string;
}

export const ResultsTable = ({ documents }: { documents: Document[] }) => {
    let rows: Row[] = documents.map(document => {
        return {
            name: document.name,
            jurisdiction: document.jurisdiction,
            date: getDate(document, dateOptions),
            type: document.doc_type,
            url: document.url,
            extract: document.extract,
            category: document.category,
            summary1: document.summary1,
            summary_version: document.summary_version,
            most_cited: document.most_cited,
            id: document.doc_id,
            doc_id: document.doc_id,
            pdf_url: document.pdf_url
        };
    });

    useEffect(() => {
        rows = documents.map(document => {
            return {
                name: document.name,
                jurisdiction: document.jurisdiction,
                date: getDate(document, dateOptions),
                type: document.doc_type,
                url: document.url,
                extract: document.extract,
                category: document.category,
                summary1: document.summary1,
                summary_version: document.summary_version,
                most_cited: document.most_cited,
                id: document.doc_id,
                doc_id: document.doc_id,
                pdf_url: document.pdf_url
            };
        });
    }, [documents]);

    return (
        <Sheet className={`${styles.answerContainer}`}>
            <Table size={"lg"}>
                <tbody>
                    {rows.map(row => (
                        <Row key={row.name} row={row} />
                    ))}
                </tbody>
            </Table>
        </Sheet>
    );
};

export const Row = ({ row }: { row: Row }) => {
    const isPDF = (url: string) => {
        return url.toLowerCase().endsWith(".pdf");
    };

    return (
        <>
            <tr key={row.name}>
                <td>
                    <Sheet>
                        <Typography component={"a"} href={row.url} level={"body-md"}>
                            {row.name}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                            {row.category && <Typography level={"body-xs"}>{row.category.substring(0, 10)}</Typography>}
                            {row.category && row.jurisdiction && <Typography level={"body-xs"}>&nbsp;·&nbsp;</Typography>}
                            {row.jurisdiction && <Typography level={"body-xs"}>{row.jurisdiction}</Typography>}
                            {row.jurisdiction && row.date && <Typography level={"body-xs"}>&nbsp;·&nbsp;</Typography>}
                            {row.date && <Typography level={"body-xs"}>{row.date}</Typography>}
                        </Box>
                        {row.most_cited && <FrequentlyCitedLabel />}
                    </Sheet>
                    <Sheet sx={{ p: 1, pl: 3, pt: 3, pb: 3 }}>
                        <Typography level="body-sm" component="div">
                            {row.extract}
                        </Typography>
                    </Sheet>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 3
                        }}
                    >
                        <Button
                            startDecorator={<Launch />}
                            variant={"plain"}
                            size={"md"}
                            component="a"
                            href={row.url}
                            target="_blank"
                            sx={{ textDecoration: "none" }}
                            rel="noopener noreferrer"
                        >
                            Original
                        </Button>
                        {isPDF(row.pdf_url || row.url) && <ChatPDFButton document={row} />}

                        {row.summary_version === 4 && row.summary1 && <CaseNoteViewer summary={JSON.parse(row.summary1)} />}
                    </Box>
                </td>
            </tr>
        </>
    );
};
