import React from "react";
import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import styles from "./Answer.module.css";
import { animations } from "../../util/utils";

export const AnswerLoading = ({ gifsLoaded }: { gifsLoaded: boolean }) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    const animation = animations[Math.floor(Math.random() * animations.length)];

    if (!gifsLoaded) {
        return null;
    }

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <img className={styles.loading} loading="eager" src={animation} alt="Loading..." />
            </Stack>
        </animated.div>
    );
};
