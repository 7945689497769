import React from "react";
import { Button, Badge } from "@mui/joy";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useNavigate } from "react-router-dom";

interface ChatPDFButtonProps {
    document: DocumentMinimumFIelds;
}

interface DocumentMinimumFIelds {
    doc_id: string;
    pdf_url?: string;
    url?: string;
    // eslint-disable-next-line
    [key: string]: any;
}

export const ChatPDFButton: React.FC<ChatPDFButtonProps> = ({ document }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/pdf-chat", {
            state: { pdfUrl: document.pdf_url || document.url, documentId: document.doc_id }
        });
    };
    return (
        <Badge
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            size="sm"
            badgeContent="new"
        >
            <Button startDecorator={<ChatOutlinedIcon />} size="md" variant="plain" onClick={handleClick}>
                Chat PDF
            </Button>
        </Badge>
    );
};
