import React, { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { CopyButton } from "../CopyButton";
import { isMobile, removeDuplicates } from "../../util/utils";
import { Box, Button, Sheet, Typography } from "@mui/joy";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";
import { Quote } from "../../pages/pdfChat/types";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    triggerMoreResults?: () => void;
    chatType: string;
    onQuoteClick?: (quote: Quote) => void;
}

const removeLabels = (text: string) => {
    const labels = ["[frequently_cited]"];
    labels.forEach(label => {
        text = text?.replace(label, "");
    });
    return text;
};

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    triggerMoreResults,
    chatType,
    onQuoteClick
}: Props) => {
    const followupQuestions = answer.choices[0]?.context?.followup_questions;
    const quotes: Quote[] = answer.choices[0].context?.quotes?.length > 0 ? JSON.parse(answer.choices[0].context.quotes) : [];
    const messageContent = removeLabels(answer.choices[0].message.content);
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);
    const sanitizedAnswerHtml = `<div>${parsedAnswer.answerHtml}</div>`;

    return (
        <Sheet className={`${styles.answerContainer} ${isSelected && styles.selected}`}>
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <div />
                    <Box sx={{ display: "flex", direction: "row" }}>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.choices[0].context.data_points?.length && !answer.documents?.length}
                        />
                        <CopyButton textToCopy={messageContent} />
                    </Box>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        // @ts-expect-error - index is not in the type
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        quote: ({ node, ...props }) => {
                            // eslint-disable-next-line
                            const index = props.index;
                            const quote = quotes[index];
                            return (
                                <div
                                    style={{
                                        borderLeft: "4px solid #ccc",
                                        paddingLeft: "16px",
                                        fontStyle: "italic",
                                        color: "#555",
                                        margin: "16px 0"
                                    }}
                                >
                                    {quote?.text || ""}
                                    {quote?.highlights?.length > 0 && (
                                        <Button
                                            className="supContainer"
                                            onClick={() => (onQuoteClick ? onQuoteClick(quote) : {})}
                                            variant={"outlined"}
                                            size={"sm"}
                                            sx={{ marginLeft: "10px" }}
                                        >
                                            Jump to
                                        </Button>
                                    )}
                                </div>
                            );
                        }
                    }}
                    className={styles.answerText}
                >
                    {sanitizedAnswerHtml}
                </ReactMarkdown>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <>
                                    {isMobile() ? (
                                        <>
                                            <a
                                                key={i}
                                                className={styles.citation}
                                                title={x}
                                                href={answer.documents[i].url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {`${++i}. ${removeLabels(x)}`}{" "}
                                            </a>{" "}
                                            {x.includes("[frequently_cited]") && (
                                                <div className={styles.frequentlyCitedLabels}>
                                                    <FrequentlyCitedLabel />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                                {`${++i}. ${removeLabels(x)}`}
                                            </a>
                                            {x.includes("[frequently_cited]") && (
                                                <div className={styles.frequentlyCitedLabels}>
                                                    <FrequentlyCitedLabel />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!answer.documents?.length && !parsedAnswer.citations?.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Documents referenced:</span>
                        {removeDuplicates(answer.documents.slice(0, 7), "name").map((x, i) => {
                            const path = x.url;
                            return (
                                <>
                                    {isMobile() ? (
                                        <a key={i} className={styles.citation} href={path} target="_blank" rel="noopener noreferrer">
                                            {`${++i}. ${x.name}`}
                                        </a>
                                    ) : (
                                        <a key={i} className={styles.citation} onClick={() => onCitationClicked(path)}>
                                            {`${++i}. ${x.name}`}
                                        </a>
                                    )}
                                </>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {chatType == "chat" && (
                <Box sx={{ display: "flex", flexDirection: "row-reverse", paddingTop: "1vh" }}>
                    <Button size="sm" startDecorator={<LowPriorityIcon />} color="primary" variant={"plain"} onClick={triggerMoreResults}>
                        Try with more results
                    </Button>
                </Box>
            )}
            <Typography className={styles.disclaimer} level="body-xs">
                This is not legal advice.
            </Typography>
        </Sheet>
    );
};
