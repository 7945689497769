import styles from "./FrequentlyCitedLabel.module.css";
import { Chip } from "@mui/joy";

export const FrequentlyCitedLabel = () => {
    return (
        <Chip variant="solid" size={"sm"} color={"primary"} className={styles.frequentlyCitedLabel}>
            <b>Frequently Cited</b>
        </Chip>
    );
};
