import * as React from "react";
import { Authentication } from "../Authentication";
import { Box } from "@mui/joy";
import { CountrySelector } from "../CountrySelector";

export const UserMenu = () => {
    return (
        <Box sx={{ display: "flex", direction: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Authentication />
            <CountrySelector />
        </Box>
    );
};
